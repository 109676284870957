#home {
  position: relative;
  background-color: var(--primary-color);

  .app__wrapper {
    padding: 0;
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;

  @media screen and (max-width: 2000px) {
    width: 50%;
    margin-right: 0rem;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 2rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  h1 {
    color: var(--lavendar-color);

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  .p-text {
    margin: 1rem 0 1.75rem;
    font-size: 1.25rem;
    font-weight: 400;

    span {
      color: var(--secondary-color);
    }
  }

  .app__resume {
    margin-top: 2.75rem;
    color: var(--lavendar-color);
    // text-decoration: none;
    font-size: 1.2rem;
    transition: all 0.3s ease-in;
    padding: 2px;
    font-weight: bold;

    &:hover {
      background-color: var(--lavendar-color);
      color: white;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: center;
    align-items: center;
  }
}

.app__header-img {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
    object-fit: contain;
    border-radius: 50%;

    @media screen and (max-width: 1200px) {
      width: 80%;
      margin: 2rem 0 2rem;
    }
  }
}
