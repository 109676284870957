#skills {
  background-color: var(--black-color-1);
}

.app__skills {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 3rem 2rem 0;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  //   @media screen and (max-width: 1200px) {
  //     padding: 1rem;
  //   }

  .head-text {
    text-align: center;
    color: var(--white-color);
  }
}

.app__skillSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__skillSection-item {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem;
  background-color: var(--black-color-2);
  border-radius: 15px;
  color: var(--lavendar-color);

  .icon {
    width: 100%;
    height: 120px;
    padding: 5px;
  }

  .skill__text {
    font-family: var(--font-base);
    margin: 1rem;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 2000px) {
    width: 350px;
    margin: 2rem 4rem;

    img {
      width: 100%;
      height: 290px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 150px;
    margin: 0.5rem;

    img {
      width: 100%;
      height: 50px;
    }

    .skill__text {
      font-size: 1.25rem;
    }
  }
}
