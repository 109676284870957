.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.head-text {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: var(--font-heading);

  span {
    color: var(--secondary-color);
  }
}

.p-text {
  font-size: 1rem;
  text-align: left;
  color: var(--black-color-1);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}
