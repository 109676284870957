#projects {
  h1 {
    color: var(--lavendar-color);
  }
}

.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: var(--primary-color);
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 350px;
    flex-direction: column;

    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--white-color);
    color: var(--black-color-2);

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }

  .app__work-img {
    width: 100%;
    height: 300px;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
      height: 350px;
    }
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;

    font-weight: bold;
  }

  .app__work-links {
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      margin: 0.5rem 0.5rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;

      svg {
        width: 60%;
        height: 60%;
        color: var(--white-color);
      }
    }
  }

  .p-text {
    text-align: center;
  }

  .app__work-tag {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;

    .work-tag {
      font-size: 0.85rem;
      color: var(--white-color);
      background-color: var(--secondary-color);
      padding: 5px 10px;
      margin-right: 0.5rem;
      border-radius: 10px;
      text-transform: uppercase;
    }
  }
}
